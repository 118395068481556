import { AdlRankResponse } from "../../../codegen-api/api";
import { Bar, Wrapper } from "./style";

interface IADLIndicatorProps {
  value: AdlRankResponse;
  style?: React.CSSProperties;
}

export function ADLIndicator({ value, style }: IADLIndicatorProps) {
  return (
    <Wrapper style={style}>
      <Bar
        rank={
          value === AdlRankResponse.Low ||
          value === AdlRankResponse.Medium ||
          value === AdlRankResponse.High
            ? value
            : undefined
        }
      />
      <Bar
        rank={
          value === AdlRankResponse.Medium || value === AdlRankResponse.High
            ? value
            : undefined
        }
      />
      <Bar rank={value === AdlRankResponse.High ? value : undefined} />
    </Wrapper>
  );
}
