import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { Campaign, LeaderboardWrapper, LogoContainer } from "./style";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import {
  Body,
  Data,
  FONT_SIZE,
  Header,
  Label,
} from "../../constants/design/fontSize";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FlexWrapper, SPACING } from "../../constants/design/spacing";
import SegmentControl from "../../components/shared/SegmentedControl";
import { getAssetLogo } from "../../utils/asset/assets";
import { CampaignDetails, CampaignEnum } from ".";
import { PageEndpointEnum } from "../../enums/endpoint";
import { useCountdown } from "./useCountdown";
import useIndexWSS from "../../hooks/wss/useIndexWSS";

export enum FilterEnum {
  ALL = "all",
  LIVE = "live",
  OLD = "old",
}

function MainPage() {
  const { isMobileScreen } = useScreenSize();
  const { t } = useTranslation("app", { keyPrefix: "pages.CampaignsPage" });
  const navigate = useNavigate();

  const { index: indexEigen } = useIndexWSS("EIGEN");

  const [activeFilter, setActiveFilter] = useState<FilterEnum>(FilterEnum.ALL);

  const countdownMapping = useCountdown();

  const rewardsPaidOut = useMemo(
    () =>
      indexEigen?.price
        ? Number(indexEigen.price) * CampaignDetails[CampaignEnum.WSB].rewards
        : undefined,
    [indexEigen]
  );

  const showCampaign = useCallback(
    (campaign: CampaignEnum) => {
      const { isLive, isUpcoming } = countdownMapping[campaign] || {};
      switch (activeFilter) {
        case FilterEnum.ALL:
          return true;
        case FilterEnum.LIVE:
          return isLive;
        case FilterEnum.OLD:
          return !isLive && !isUpcoming;
        default:
          return true;
      }
    },
    [activeFilter, countdownMapping]
  );

  const handleCampaignClick = (campaign: CampaignEnum) => {
    navigate(`${PageEndpointEnum.CAMPAIGNS}/${campaign}`);
  };

  const createCountdownText = (
    unit: number,
    label: string,
    isUpcoming: boolean
  ) => `${unit} ${t(label)} ${t(isUpcoming ? "till_start" : "left")}`;

  const renderCampaign = (campaign: CampaignEnum) => {
    const { days, hours, minutes, seconds, isLive, isUpcoming } =
      countdownMapping[campaign] || {};
    let countdownText = t("campaign_over");
    const { asset, epoch, rewards } = CampaignDetails[campaign];
    if (isUpcoming || isLive) {
      countdownText =
        days > 0
          ? createCountdownText(days, days > 1 ? "days" : "day", isUpcoming)
          : hours > 0
          ? createCountdownText(hours, hours > 1 ? "hours" : "hour", isUpcoming)
          : minutes > 0
          ? createCountdownText(
              minutes,
              minutes > 1 ? "minutes" : "minute",
              isUpcoming
            )
          : seconds > 0
          ? createCountdownText(
              seconds,
              seconds > 1 ? "seconds" : "second",
              isUpcoming
            )
          : countdownText;
    }

    const rewardsUSD = () => {
      switch (campaign) {
        case CampaignEnum.WSB:
          return Number(indexEigen?.price) * CampaignDetails[campaign].rewards;
        case CampaignEnum.USDT:
          return CampaignDetails[campaign].rewards;
        default:
          return 0;
      }
    };
    return (
      showCampaign(campaign) && (
        <Campaign onClick={() => handleCampaignClick(campaign)} key={campaign}>
          <FlexWrapper gap={SPACING.two}>
            <LogoContainer size={40} borderWidth={2} isLive={isLive}>
              <img
                src={getAssetLogo(asset)}
                width={40}
                height={40}
                alt={asset}
              />
            </LogoContainer>
            <FlexWrapper flexColumn gap={SPACING.one} alignItems="flex-start">
              <Header number="seven" color={TEXT_COLORS.one}>
                {isMobileScreen
                  ? t(`${campaign.toLowerCase()}_title_short`, {
                      epoch,
                    })
                  : t(`${campaign.toLowerCase()}_title`, {
                      epoch,
                    })}
              </Header>
              <Label number="two" color={TEXT_COLORS.three}>
                {countdownText}
              </Label>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper flexColumn gap={SPACING.one} alignItems="flex-end">
            <Header number="seven" color={TEXT_COLORS.one}>
              {rewardsUSD() ? currency(rewardsUSD()).format() : "- - -"}
            </Header>
            <Label number="two" color={TEXT_COLORS.three}>
              {currency(rewards, {
                symbol: "",
                precision: 0,
              }).format()}{" "}
              {asset}
            </Label>
          </FlexWrapper>
        </Campaign>
      )
    );
  };

  const sortedCampaigns = useMemo(
    () =>
      Object.values(CampaignEnum).sort((a, b) => {
        const startTimeA = BigInt(CampaignDetails[a].startTime);
        const startTimeB = BigInt(CampaignDetails[b].startTime);
        return startTimeB > startTimeA ? 1 : -1; // Sort by descending startTime
      }),
    []
  );

  return (
    <LeaderboardWrapper isMobileScreen={isMobileScreen}>
      <FlexWrapper
        flexColumn
        gap={SPACING.three}
        style={{ padding: isMobileScreen ? SPACING.three : 0 }}
      >
        <Header number="four">{t("aevo_campaigns")}</Header>
        <Body number="three" color={TEXT_COLORS.two}>
          {t("campaign_desc")}
        </Body>
        <FlexWrapper flexColumn gap={SPACING.two}>
          <Label number="three" color={TEXT_COLORS.three}>
            {t("total_rewards")}
          </Label>
          <Data number="one">
            {rewardsPaidOut ? currency(rewardsPaidOut).format() : "- - -"}
          </Data>
        </FlexWrapper>
        <FlexWrapper flexColumn gap={SPACING.three}>
          <SegmentControl
            config={{
              widthType: "fullWidth",
              backgroundColor: COLORS.neutral.six,
              activeBackgroundColor: COLORS.neutral.five,
              borderRadius: "10px",
              button: {
                height: 32,
                fontSize: FONT_SIZE.one,
                px: SPACING.three,
              },
            }}
            hasDropdown
            segments={Object.values(FilterEnum).map((filter) => ({
              value: filter,
              display: t(filter),
              textColor:
                activeFilter === filter ? TEXT_COLORS.one : TEXT_COLORS.three,
            }))}
            value={activeFilter}
            onSelect={(value) => setActiveFilter(value as FilterEnum)}
          />
        </FlexWrapper>
        {sortedCampaigns.map(renderCampaign)}
      </FlexWrapper>
    </LeaderboardWrapper>
  );
}

export default MainPage;
