/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AxiosError } from "axios";
import currency from "currency.js";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FieldErrors,
  FieldValues,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/arrow-down.svg";
import { ReactComponent as Info } from "../../../../assets/svg/info.svg";
import {
  GenericErrorResponse,
  GetAccount200ResponsePositionsInnerPartialPositionTriggers,
  MarginTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import { COLORS, FIELD_COLORS } from "../../../../constants/design/colors";
import { SPACING } from "../../../../constants/design/spacing";
import { MarketInstrumentContext } from "../../../../contexts/MarketInstrumentContext";
import { IPerpsMarket } from "../../../../contexts/MarketInstrumentContext/useGetMarkets";
import { FormValidatorKeysEnum } from "../../../../enums/form";
import { useGetAccount } from "../../../../hooks/api/account/useGetAccount";
import { useMarginType } from "../../../../hooks/api/margin/useMarginType";
import { useToast } from "../../../../hooks/toast";
import { AssetResponse } from "../../../../utils/asset";
import { getAssetLogo } from "../../../../utils/asset/assets";
import { roundToStepSize } from "../../../../utils/format";
import { ToastEnum, ToastStatusEnum } from "../../../../utils/toast";
import { Button, ButtonThemeEnum } from "../../../Buttons/styles";
import { NotificationTypeEnum } from "../../../ConfirmationModal/TPSLModalContent/TPSLContent.types";
import { InputRow } from "../../../ConfirmationModal/style";
import { TooltipContainer } from "../../../PortfolioSettings/Collateral/style";
import { Chevron } from "../../../shared/Chevron/style";
import Dropdown from "../../../shared/Dropdown";
import { TooltipWrapper } from "../../../shared/Header/AccountModal/style";
import { Input } from "../../../shared/Input";
import RowActionButton from "../../../shared/RowActionButton";
import TooltipExplanation from "../../../shared/Tooltip";
import { IInputProps } from "../../form";
import {
  DropdownItem,
  InputWrapper,
  Label,
  MarginText,
  PartialMaxButton,
  WarningLabel,
} from "../../style";
import {
  CoreRing,
  DropdownTitle,
  InnerRing,
  InputButtonsWrapper,
  LabelFlexWrapper,
  LearnMoreText,
  LeverageContentWrapper,
  LimitPriceSelectors,
  MarginButton,
  MarginLabel,
  MarginSelectorWrapper,
  MarginTypeText,
  OuterRing,
  PartialPositionPercentage,
  Slider,
  SliderBackground,
  SliderInputWrapper,
  SliderPattern,
  SliderPatternWrapper,
  SliderSmallWrapper,
  SliderWrapper,
  StyledThumb,
  StyledTrack,
  X,
} from "../style";
import { LeverageModal } from "./LeverageModal";
import { MarginModal } from "./MarginModal";

export enum PerpFormFieldKeyEnum {
  PRICE = "price",
  AMOUNT = "amount",
  LEVERAGE = "leverage",
  TRIGGER_PRICE = "triggerPrice",
  ORDER_TP_TRIGGER_PRICE = "orderTpTriggerPrice",
  ORDER_SL_TRIGGER_PRICE = "orderSlTriggerPrice",
  MARGIN_TYPE = "marginType",
}

export type IPerpsFormFieldValues = {
  [key in PerpFormFieldKeyEnum]: string;
};

export const usePerpsForm = (
  props?: UseFormProps<IPerpsFormFieldValues, any>
) =>
  useForm<IPerpsFormFieldValues>({
    mode: "onSubmit",
    ...props,
  });

export enum LimitPriceShortcutEnum {
  BEST_PRICE = "bestPrice",
  ONE_PERCENT = "onePercent",
  TWO_PERCENT = "twoPercent",
  FIVE_PERCENT = "fivePercent",
}

interface IPriceInputProps extends IInputProps {
  isMobileScreen?: boolean;
  minPrice: number;
  placeholder?: string;
  isStopOrder?: boolean;
  orderDirection?: SideResponse;
  onSelectLimitShortcut?: (shortcut: LimitPriceShortcutEnum) => void;
}

interface IPartialPositionInputProps {
  errors: FieldErrors<FieldValues>;
  inputRegister:
    | UseFormRegisterReturn<"partialTakeProfitPositionAmount">
    | UseFormRegisterReturn<"partialStopLossPositionAmount">;
  placeholder: string;
  amount: string;
  partialPositionTriggers: GetAccount200ResponsePositionsInnerPartialPositionTriggers;
  partialPositionType: "TP" | "SL";
  setMaxPartialSLValue?: (value: string | number) => void;
  setMaxPartialTPValue?: (value: string | number) => void;
  onChangeSliderValue: (value: number) => void;
  positionAmount: number;
  amountPrecision: number;
  minAmountSize: number;
}

export function PriceInput({
  isMobileScreen,
  register,
  errors,
  warning,
  minPrice,
  placeholder,
  isStopOrder,
  orderDirection,
  onSelectLimitShortcut,
}: IPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: tooltips } = useTranslation("tooltips");
  const { t: formError } = useTranslation("formErrors", { keyPrefix: "price" });
  const error = errors?.[PerpFormFieldKeyEnum.PRICE];
  const hasError = Boolean(error?.type);

  return (
    <InputWrapper isMobileScreen={isMobileScreen}>
      <LabelFlexWrapper>
        <Label>{t("limit_price")}</Label>
        {isStopOrder && (
          <TooltipExplanation
            title={t("limit_price")}
            explanation={tooltips("stopLimitPrice")}
            renderContent={({ ref, ...triggerHandler }) => (
              <TooltipWrapper ref={ref} {...triggerHandler}>
                <Info />
              </TooltipWrapper>
            )}
          />
        )}
      </LabelFlexWrapper>
      <Input
        error={hasError}
        placeholder={placeholder ?? "0"}
        type="number"
        {...register}
      />
      {onSelectLimitShortcut && (
        <LimitPriceSelectors isSell={orderDirection === SideResponse.Sell}>
          <button
            type="button"
            onClick={() =>
              onSelectLimitShortcut(LimitPriceShortcutEnum.BEST_PRICE)
            }
          >
            {orderDirection === SideResponse.Buy
              ? t("best_bid")
              : t("best_ask")}
          </button>
          <button
            type="button"
            onClick={() =>
              onSelectLimitShortcut(LimitPriceShortcutEnum.ONE_PERCENT)
            }
          >
            1% <ArrowDown />
          </button>
          <button
            type="button"
            onClick={() =>
              onSelectLimitShortcut(LimitPriceShortcutEnum.TWO_PERCENT)
            }
          >
            2% <ArrowDown />
          </button>
          <button
            type="button"
            onClick={() =>
              onSelectLimitShortcut(LimitPriceShortcutEnum.FIVE_PERCENT)
            }
          >
            5% <ArrowDown />
          </button>
        </LimitPriceSelectors>
      )}
      {error?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>{formError("required")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.moreThanZero && (
        <WarningLabel>{formError("moreThanZero")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {formError("cannotBeLessThanSize", { size: minPrice })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.limitPriceAboveTriggerPrice && (
        <WarningLabel>{formError("priceAboveTriggerPrice")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.limitPriceBelowTriggerPrice && (
        <WarningLabel>{formError("priceBelowTriggerPrice")}</WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface ITriggerPriceInputProps extends IPriceInputProps {
  markPrice?: string;
}

interface IExtraTriggerPriceInputProps extends ITriggerPriceInputProps {
  markPrice?: string;
  leftAccessory?: JSX.Element;
  resetInput: () => void;
}

export function TriggerPriceInput({
  isMobileScreen,
  register,
  errors,
  warning,
  minPrice,
  placeholder,
  markPrice,
}: ITriggerPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: tooltips } = useTranslation("tooltips");
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "triggerPrice",
  });
  const error = errors?.[PerpFormFieldKeyEnum.TRIGGER_PRICE];
  const hasError = Boolean(error?.type);
  return (
    <InputWrapper isMobileScreen={isMobileScreen}>
      <LabelFlexWrapper>
        <Label>{t("trigger_price")}</Label>
        <TooltipExplanation
          title={t("trigger_price")}
          explanation={tooltips("stopTriggerPrice")}
          renderContent={({ ref, ...triggerHandler }) => (
            <TooltipWrapper ref={ref} {...triggerHandler}>
              <Info />
            </TooltipWrapper>
          )}
        />
      </LabelFlexWrapper>
      <Input
        error={hasError}
        placeholder={placeholder ?? "0"}
        type="number"
        {...register}
      />
      {error?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>{formError("required")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.moreThanZero && (
        <WarningLabel>{formError("moreThanZero")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {formError("cannotBeLessThanSize", { size: minPrice })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceAboveMark && (
        <WarningLabel>
          {formError("priceAboveMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceBelowMark && (
        <WarningLabel>
          {formError("priceBelowMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

export function OrderTPTriggerPriceInput({
  register,
  errors,
  warning,
  minPrice,
  placeholder,
  markPrice,
  resetInput,
}: IExtraTriggerPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "triggerPrice",
  });
  const error = errors?.[PerpFormFieldKeyEnum.ORDER_TP_TRIGGER_PRICE];
  const hasError = Boolean(error?.type);
  return (
    <InputWrapper>
      <InputRow>
        <Input
          error={hasError}
          placeholder={placeholder ?? "0"}
          type="number"
          {...register}
        />
        <RowActionButton
          variant={"close"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            resetInput();
          }}
          style={{ minWidth: 48, minHeight: 48 }}
        />
      </InputRow>
      {error?.type === FormValidatorKeysEnum.moreThanZero && (
        <WarningLabel>{formError("moreThanZero")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {formError("cannotBeLessThanSize", { size: minPrice })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceAboveMark && (
        <WarningLabel>
          {formError("priceAboveMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceBelowMark && (
        <WarningLabel>
          {formError("priceBelowMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

export function OrderSLTriggerPriceInput({
  register,
  errors,
  warning,
  minPrice,
  placeholder,
  markPrice,
  resetInput,
}: IExtraTriggerPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "triggerPrice",
  });
  const error = errors?.[PerpFormFieldKeyEnum.ORDER_SL_TRIGGER_PRICE];
  const hasError = Boolean(error?.type);
  return (
    <InputWrapper>
      <InputRow>
        <Input
          error={hasError}
          placeholder={placeholder ?? "0"}
          type="number"
          {...register}
        />
        <RowActionButton
          variant={"close"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            resetInput();
          }}
          style={{ minWidth: 48, minHeight: 48 }}
        />
      </InputRow>
      {error?.type === FormValidatorKeysEnum.moreThanZero && (
        <WarningLabel>{formError("moreThanZero")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {formError("cannotBeLessThanSize", { size: minPrice })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceAboveMark && (
        <WarningLabel>
          {formError("priceAboveMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceBelowMark && (
        <WarningLabel>
          {formError("priceBelowMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

export function TakeProfitInput({
  isMobileScreen,
  register: takeProfitRegister,
  errors,
  placeholder,
  markPrice,
  resetInput,
  leftAccessory,
}: IExtraTriggerPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "triggerPrice",
  });

  // Determine which error to display based on the name from takeProfitRegister
  const error =
    takeProfitRegister.name === "tpTriggerPrice"
      ? errors?.tpTriggerPrice
      : errors?.partialTPTriggerPrice;
  const hasError = Boolean(error?.type);
  return (
    <InputWrapper isMobileScreen={isMobileScreen} style={{ width: "100%" }}>
      <LabelFlexWrapper>
        <Label>{t("take_profit")}</Label>
      </LabelFlexWrapper>
      <InputRow>
        <Input
          error={hasError}
          placeholder={placeholder ?? "0"}
          type="number"
          leftAccessory={leftAccessory}
          {...takeProfitRegister}
        />
        <RowActionButton
          variant={"close"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            resetInput();
          }}
          style={{ minWidth: 48, minHeight: 48 }}
        />
      </InputRow>
      {error?.type === FormValidatorKeysEnum.triggerPriceAboveMark && (
        <WarningLabel>
          {formError("priceAboveMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceBelowMark && (
        <WarningLabel>
          {formError("priceBelowMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>{formError("decimalsTooSmall")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.roiTooHigh && (
        <WarningLabel>{formError("roiTooHigh")}</WarningLabel>
      )}
    </InputWrapper>
  );
}

export function StopLossInput({
  isMobileScreen,
  register: stopLossRegister,
  errors,
  placeholder,
  markPrice,
  resetInput,
  leftAccessory,
}: IExtraTriggerPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "triggerPrice",
  });
  const error =
    stopLossRegister.name === "slTriggerPrice"
      ? errors?.slTriggerPrice
      : errors?.partialSLTriggerPrice;
  const hasError = Boolean(error?.type);
  return (
    <InputWrapper isMobileScreen={isMobileScreen} style={{ width: "100%" }}>
      <LabelFlexWrapper>
        <Label>{t("stop_loss")}</Label>
      </LabelFlexWrapper>
      <InputRow>
        <Input
          error={hasError}
          placeholder={placeholder ?? "0"}
          type="number"
          leftAccessory={leftAccessory}
          {...stopLossRegister}
        />
        <RowActionButton
          variant={"close"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            resetInput();
          }}
          style={{ minWidth: 48, minHeight: 48 }}
        />
      </InputRow>
      {error?.type === FormValidatorKeysEnum.triggerPriceAboveMark && (
        <WarningLabel>
          {formError("priceAboveMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceBelowMark && (
        <WarningLabel>
          {formError("priceBelowMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>{formError("decimalsTooSmall")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.roiTooLow && (
        <WarningLabel>{formError("roiTooLow")}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface IOrderSizeInputProps extends IInputProps {
  isMobileScreen?: boolean;
  style?: CSSProperties;
  selectedAsset?: AssetResponse;
  onChangeAsset: (asset?: AssetResponse) => void;
  minAmountSize: number;
  underlyingAsset?: AssetResponse;
  existingPositionSize?: string;
  existingOrderInfo?: {
    newOrderSizeLimit: string;
    side: SideResponse;
    size: string;
  };
  placeholder?: string;
  minOrderValue?: string;
  maxOrderValue?: string;
}

export function OrderSizeInput({
  isMobileScreen,
  register,
  errors,
  warning,
  style,
  customError,
  selectedAsset,
  onChangeAsset,
  minAmountSize,
  existingPositionSize,
  existingOrderInfo,
  underlyingAsset,
  placeholder,
  minOrderValue,
  maxOrderValue,
}: IOrderSizeInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "orderSize",
  });

  const [show, setShow] = useState(false);
  const error = errors?.[PerpFormFieldKeyEnum.AMOUNT];
  const hasInputError = Boolean(error?.type);
  const hasError = hasInputError || Boolean(customError);
  const logo = selectedAsset
    ? getAssetLogo(selectedAsset)
    : getAssetLogo("USDC");

  return (
    <InputWrapper isMobileScreen={isMobileScreen} style={style}>
      <LabelFlexWrapper>
        <Label>{t("order_size")}</Label>
      </LabelFlexWrapper>
      <Input
        error={hasError}
        placeholder={placeholder || "0"}
        {...register}
        rightAccessory={
          <Dropdown
            onToggle={(open) => setShow(open)}
            toggleStyles={{
              borderRadius: 100,
              padding: `0 ${SPACING.one}px`,
              background: FIELD_COLORS.two,
              width: 56,
            }}
            dropdownMenuContainerStyles={{
              overflow: "hidden",
              borderRadius: "8px",
            }}
            title={
              <DropdownTitle>
                {logo && (
                  <img
                    alt={selectedAsset}
                    src={logo}
                    style={{
                      marginRight: SPACING.one,
                      width: 24,
                      height: 24,
                    }}
                  />
                )}
                <Chevron direction={show ? "up" : "down"} />
              </DropdownTitle>
            }
            items={[
              {
                label: (
                  <DropdownItem isSelected={!selectedAsset}>
                    <img alt={"USDC"} src={getAssetLogo("USDC")} />
                    USDC
                  </DropdownItem>
                ),
                onSelect: () => onChangeAsset(undefined),
              },
              ...(underlyingAsset
                ? [
                    {
                      label: (
                        <DropdownItem isSelected={!!selectedAsset}>
                          <img
                            alt={underlyingAsset}
                            src={getAssetLogo(underlyingAsset)}
                          />
                          {underlyingAsset}
                        </DropdownItem>
                      ),
                      onSelect: () => onChangeAsset(underlyingAsset),
                    },
                  ]
                : []),
            ]}
          />
        }
      />
      {error?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>{formError("required")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.notEnoughBalance && (
        <WarningLabel>{formError("insufficient_buying_power")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.reduceOnlyOrderSizeValid && (
        <WarningLabel>
          {formError("reduce_size_invalid", { size: existingPositionSize })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.orderValueTooSmall && (
        <WarningLabel>
          {formError("order_value_below_min", {
            value: currency(minOrderValue || 0).format(),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.orderValueTooLarge && (
        <WarningLabel>
          {formError("order_value_above_max", {
            value: currency(maxOrderValue || 0).format(),
          })}
        </WarningLabel>
      )}
      {error?.type ===
        FormValidatorKeysEnum.reduceOnlyOrderSizeValidWithExistingOrder && (
        <WarningLabel>
          {existingOrderInfo?.side === SideResponse.Buy
            ? formError("reduce_size_invalid_existing_order_long", {
                newOrderSize: existingOrderInfo?.newOrderSizeLimit,
                existingOrderSize: existingOrderInfo?.size,
                positionSize: existingPositionSize,
              })
            : formError("reduce_size_invalid_existing_order_short", {
                newOrderSize: existingOrderInfo?.newOrderSizeLimit,
                existingOrderSize: existingOrderInfo?.size,
                positionSize: existingPositionSize,
              })}
        </WarningLabel>
      )}
      {Boolean(
        error?.type === FormValidatorKeysEnum.moreThanZero ||
          error?.type === FormValidatorKeysEnum.decimalsTooSmall
      ) && (
        <WarningLabel>
          {formError("min_order_size", { size: minAmountSize })}
        </WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
      {Boolean(!hasInputError && customError) && (
        <WarningLabel>{customError}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface ILeverageInputProps extends Omit<IInputProps, "register"> {
  leverageColor: string;
  leverage?: number;
  onChangeLeverage?: (leverage: number) => void;
  maxLeverage: number;
}

interface IReduceOnlyMaxSizeInputProps extends Omit<IInputProps, "register"> {
  leverageColor: string;
  orderSize?: number;
  onChangeOrderSize?: (orderSize: number) => void;
  maxOrderSize: number;
}

export function LeverageSelectInput({
  errors,
  warning,
  leverageColor,
  leverage,
  onChangeLeverage,
  maxLeverage = 20,
}: ILeverageInputProps) {
  // Slider pattern is dependent on the maxLeverage
  const sliderPatterns = [0, 1, 2, 3, 4, 5];

  useEffect(() => {
    if (leverage && leverage > maxLeverage) {
      onChangeLeverage?.(maxLeverage);
    }
  }, [leverage, maxLeverage, onChangeLeverage]);

  return (
    <InputWrapper isMobileScreen>
      <InputButtonsWrapper>
        <SliderInputWrapper showSlider>
          <SliderWrapper>
            <Slider
              renderTrack={(props, { index }) => (
                <StyledTrack
                  {...(props as any)}
                  index={index}
                  color={leverageColor}
                />
              )}
              renderThumb={(props) => (
                <StyledThumb
                  {...(props as any)}
                  color={leverageColor}
                  width={`calc(100% / ${sliderPatterns.length})`}
                >
                  <div />
                </StyledThumb>
              )}
              value={Number(leverage) * 100 || 0}
              min={0}
              max={maxLeverage * 100}
              step={1}
              onChange={(value) => {
                if (typeof value === "number") {
                  onChangeLeverage?.(value / 100);
                }
              }}
            />
            <SliderBackground>
              {sliderPatterns.map((v) => {
                const currentValue = (maxLeverage / sliderPatterns.length) * v;
                return (
                  <SliderPatternWrapper
                    key={String(v)}
                    width={`calc(100% / ${sliderPatterns.length - 1})`}
                  >
                    <SliderPattern
                      activeColor={leverageColor}
                      active={currentValue <= (Number(leverage) * 100 || 0)}
                    />
                  </SliderPatternWrapper>
                );
              })}
            </SliderBackground>
          </SliderWrapper>
          <X
            style={{
              paddingRight: 0,
              margin: "auto",
              width: 50,
              textAlign: "center",
            }}
          >
            {Number(leverage) > 0
              ? ((Number(leverage) / maxLeverage) * 100).toFixed(0)
              : 0}
            %
          </X>
        </SliderInputWrapper>
      </InputButtonsWrapper>
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

export function ReduceOnlyMaxSizeInput({
  errors,
  warning,
  leverageColor,
  orderSize,
  onChangeOrderSize,
  maxOrderSize,
}: IReduceOnlyMaxSizeInputProps) {
  // Slider pattern is dependent on the order size
  const sliderPatterns = [0, 1, 2, 3, 4, 5];

  useEffect(() => {
    if (orderSize && orderSize > maxOrderSize) {
      onChangeOrderSize?.(maxOrderSize);
    }
  }, [maxOrderSize, onChangeOrderSize, orderSize]);

  return (
    <InputWrapper isMobileScreen>
      <InputButtonsWrapper>
        <SliderInputWrapper showSlider>
          <SliderWrapper>
            <Slider
              renderTrack={(props, { index }) => (
                <StyledTrack
                  {...(props as any)}
                  index={index}
                  color={leverageColor}
                />
              )}
              renderThumb={(props) => (
                <StyledThumb
                  {...(props as any)}
                  color={leverageColor}
                  width={`calc(100% / ${sliderPatterns.length})`}
                >
                  <div />
                </StyledThumb>
              )}
              value={Number(orderSize) || 0}
              min={0}
              max={maxOrderSize}
              step={1}
              onChange={(value) => {
                if (typeof value === "number") {
                  onChangeOrderSize?.(value);
                }
              }}
            />
            <SliderBackground>
              {sliderPatterns.map((v) => {
                const currentValue = (maxOrderSize / sliderPatterns.length) * v;
                return (
                  <SliderPatternWrapper
                    key={String(v)}
                    width={`calc(100% / ${sliderPatterns.length - 1})`}
                  >
                    <SliderPattern
                      activeColor={leverageColor}
                      active={currentValue <= (Number(maxOrderSize) || 0)}
                    />
                  </SliderPatternWrapper>
                );
              })}
            </SliderBackground>
          </SliderWrapper>
          <X
            style={{
              paddingRight: 0,
              margin: "auto",
              width: 50,
              textAlign: "center",
            }}
          >
            {Number(orderSize) > 0
              ? ((Number(orderSize) / maxOrderSize) * 100).toFixed(0)
              : 0}
            %
          </X>
        </SliderInputWrapper>
      </InputButtonsWrapper>
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface IMarginSelectorProps {
  form: UseFormReturn<IPerpsFormFieldValues, any>;
  side: SideResponse;
  instrument?: IPerpsMarket;
  isMobileScreen?: boolean;
}

interface IMarginIconProps {
  marginType: MarginTypeResponse;
  side: SideResponse;
  isSelected: boolean;
}
export function MarginIcon({
  marginType = MarginTypeResponse.Isolated,
  side,
  isSelected,
}: IMarginIconProps) {
  return (
    <OuterRing isSelected={isSelected} side={side}>
      <InnerRing isIsolated={marginType === MarginTypeResponse.Isolated}>
        <CoreRing side={side} />
      </InnerRing>
    </OuterRing>
  );
}
export function MarginSelector({
  form,
  side,
  instrument,
  isMobileScreen,
}: IMarginSelectorProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const [showLeverageModal, setShowLeverageModal] = useState(false);
  const [showMarginModal, setShowMarginModal] = useState(false);
  const [learnMoreMarginType, setLearnMoreMarginType] =
    useState<MarginTypeResponse>();
  const marginType = form.watch(PerpFormFieldKeyEnum.MARGIN_TYPE);
  const { data: accountData, mutate: mutateAccount } = useGetAccount();
  const { mutateMarkets } = useContext(MarketInstrumentContext);
  const { updateMarginType } = useMarginType();
  const [disabledMarginType, setDisabledMarginType] = useState<
    MarginTypeResponse | undefined
  >();
  const [disabledMarginWarning, setDisabledMarginWarning] = useState<
    string | undefined
  >();
  const { addToast, addErrorToast } = useToast();
  const accountLeverage = useMemo(
    () =>
      accountData?.leverages?.find(
        (lev) => lev.instrument_id === instrument?.instrument_id
      )?.leverage,
    [accountData?.leverages, instrument?.instrument_id]
  );

  useEffect(() => {
    const existingPosition = accountData?.positions?.find(
      (pos) => pos.instrument_id === instrument?.instrument_id
    );

    // If there is an existing position, set the margin type to the existing position's margin type
    if (existingPosition?.margin_type) {
      form.setValue(
        PerpFormFieldKeyEnum.MARGIN_TYPE,
        existingPosition.margin_type
      );

      // Disable the other option if there is an existing position
      if (existingPosition.margin_type === MarginTypeResponse.Isolated) {
        setDisabledMarginType(MarginTypeResponse.Cross);
      } else {
        setDisabledMarginType(MarginTypeResponse.Isolated);
      }
    } else {
      // Remove disabled margin type if there is no existing position
      setDisabledMarginType(undefined);

      // Otherwise, set it to account.leverage margin type
      const accountMarginType = accountData?.leverages?.find(
        (lev) => lev.instrument_id === instrument?.instrument_id
      )?.margin_type;

      if (accountMarginType) {
        form.setValue(PerpFormFieldKeyEnum.MARGIN_TYPE, accountMarginType);
      }
    }
  }, [accountData, accountData?.positions, form, instrument?.instrument_id]);

  const onUpdateMarginType = useCallback(
    async (type: MarginTypeResponse) => {
      try {
        if (marginType === type || !accountData) {
          return;
        }

        if (instrument?.instrument_id) {
          if (type === disabledMarginType) {
            setDisabledMarginWarning(
              t("margin_type_disabled", {
                marginType: t(type.toLowerCase()),
                asset: instrument.underlying_asset,
              })
            );
          } else {
            setDisabledMarginWarning(undefined);
            await updateMarginType(Number(instrument?.instrument_id), type);
            form.setValue(PerpFormFieldKeyEnum.MARGIN_TYPE, type);
            mutateAccount();
            addToast(
              {
                header: t("updated_margin_type_success"),
                status: ToastStatusEnum.SUCCESS,
                type: ToastEnum.SIMPLE,
              },
              3000
            );
          }
        }
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        addErrorToast(
          "Error updating margin type",
          genericResponseAxiosError.response?.data?.error || ""
        );
      }
    },
    [
      accountData,
      addErrorToast,
      addToast,
      disabledMarginType,
      form,
      instrument?.instrument_id,
      instrument?.underlying_asset,
      marginType,
      mutateAccount,
      t,
      updateMarginType,
    ]
  );

  const onUpdateLeverageSuccess = useCallback(() => {
    mutateAccount();
    mutateMarkets?.();
  }, [mutateAccount, mutateMarkets]);

  const onLearnMore = useCallback((type: MarginTypeResponse) => {
    setShowMarginModal(true);
    setLearnMoreMarginType(type);
    setDisabledMarginWarning(undefined);
  }, []);

  const onHideMarginModal = useCallback(() => {
    setShowMarginModal(false);
    setLearnMoreMarginType(undefined);
    setDisabledMarginWarning(undefined);
  }, []);

  return (
    <>
      <LeverageModal
        show={showLeverageModal}
        onHide={() => setShowLeverageModal(false)}
        accountData={accountData}
        instrument={instrument}
        onUpdateLeverageSuccess={onUpdateLeverageSuccess}
      />
      <MarginModal
        show={showMarginModal}
        onHide={onHideMarginModal}
        marginType={learnMoreMarginType as MarginTypeResponse}
      />
      <InputWrapper style={{ marginBottom: 0 }}>
        {!isMobileScreen && (
          <MarginLabel>{t("margin_and_leverage_settings")}</MarginLabel>
        )}
        <MarginSelectorWrapper isMobileScreen={isMobileScreen}>
          {isMobileScreen ? (
            <MarginText style={{ justifyContent: "center", flex: 1 }}>
              {t("cross")}
            </MarginText>
          ) : (
            <>
              <MarginButton
                side={side}
                isSelected={marginType === MarginTypeResponse.Cross}
                onClick={() => onUpdateMarginType(MarginTypeResponse.Cross)}
                buttonTheme={ButtonThemeEnum.NEUTRAL2}
              >
                <MarginIcon
                  isSelected={marginType === MarginTypeResponse.Cross}
                  side={side}
                  marginType={MarginTypeResponse.Cross}
                />
                <MarginTypeText>
                  <span>{t("cross")}</span>
                  <LearnMoreText
                    onClick={() => onLearnMore(MarginTypeResponse.Cross)}
                  >
                    {t("learn_more")}
                  </LearnMoreText>
                </MarginTypeText>
              </MarginButton>
              <TooltipExplanation
                title={t("isolated_margin_disabled")}
                explanation={
                  <TooltipContainer>
                    {t("isolated_margin_disabled_desc")}
                  </TooltipContainer>
                }
                renderContent={({ ref, ...triggerHandler }) => (
                  <TooltipWrapper
                    ref={ref}
                    {...triggerHandler}
                    style={{ padding: 0 }}
                  >
                    <MarginButton
                      side={side}
                      isSelected={marginType === MarginTypeResponse.Isolated}
                      onClick={() =>
                        onUpdateMarginType(MarginTypeResponse.Isolated)
                      }
                      buttonTheme={ButtonThemeEnum.NEUTRAL2}
                      disabled
                      style={{ width: "100%" }}
                    >
                      <MarginIcon
                        isSelected={marginType === MarginTypeResponse.Isolated}
                        side={side}
                        marginType={MarginTypeResponse.Isolated}
                      />
                      <MarginTypeText>
                        <span>{t("isolated")}</span>
                        <LearnMoreText
                          onClick={() =>
                            onLearnMore(MarginTypeResponse.Isolated)
                          }
                        >
                          {t("learn_more")}
                        </LearnMoreText>
                      </MarginTypeText>
                    </MarginButton>
                  </TooltipWrapper>
                )}
              />
            </>
          )}
          <Button
            buttonTheme={ButtonThemeEnum.NEUTRAL2}
            onClick={() => setShowLeverageModal(true)}
          >
            <LeverageContentWrapper>
              {accountLeverage
                ? `${accountLeverage}x`
                : instrument?.max_leverage
                ? `${instrument?.max_leverage}x`
                : "-"}
              {accountData ? <Chevron direction={"down"} /> : null}
            </LeverageContentWrapper>
          </Button>
        </MarginSelectorWrapper>
        {disabledMarginWarning ? (
          <WarningLabel>{disabledMarginWarning}</WarningLabel>
        ) : null}
      </InputWrapper>
    </>
  );
}

export function PartialPositionInput({
  errors,
  inputRegister,
  placeholder,
  amount,
  partialPositionTriggers,
  partialPositionType,
  setMaxPartialTPValue,
  setMaxPartialSLValue,
  onChangeSliderValue,
  positionAmount,
  amountPrecision,
  minAmountSize,
}: IPartialPositionInputProps) {
  const error =
    inputRegister.name === "partialTakeProfitPositionAmount"
      ? errors?.partialTakeProfitPositionAmount
      : errors?.partialStopLossPositionAmount;
  const hasError = Boolean(error?.type);
  const posAmount = Number(amount);
  const inputPositionAmount = positionAmount || 0;

  const { t } = useTranslation("formErrors", {
    keyPrefix: "amount",
  });

  const { t: tPositions } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });

  const calculateRemainingQuantity = useCallback(
    (
      _posAmount: number,
      _partialPositionTriggers: GetAccount200ResponsePositionsInnerPartialPositionTriggers
    ): number => {
      if (_partialPositionTriggers === undefined) return _posAmount;
      const { take_profit, stop_loss } = _partialPositionTriggers;

      if (
        take_profit?.length !== 0 &&
        partialPositionType === NotificationTypeEnum.TP
      ) {
        const sumTakeProfit = (take_profit ?? []).reduce(
          (acc, curr) => acc + parseFloat(curr.amount),
          0
        );
        const remainingTakeProfit = _posAmount - sumTakeProfit;
        return Number(remainingTakeProfit.toFixed(2));
      }

      if (
        stop_loss?.length !== 0 &&
        partialPositionType === NotificationTypeEnum.SL
      ) {
        const sumStopLoss = (stop_loss ?? []).reduce(
          (acc, curr) => acc + parseFloat(curr.amount),
          0
        );
        const remainingStopLoss = _posAmount - sumStopLoss;
        return Number(remainingStopLoss.toFixed(2));
      }

      return _posAmount;
    },
    [partialPositionType]
  );

  const calculatePositionPercentage = useCallback(
    (input: number, totalAmount: number) => {
      if (totalAmount <= 0) {
        return 0;
      }
      const validInputAmount = Math.max(0, Math.min(input, totalAmount));

      const percentage = (validInputAmount / totalAmount) * 100;
      return Math.round(percentage);
    },
    []
  );

  return (
    <InputWrapper
      style={{ width: "100%", marginBottom: "8px", marginTop: "8px" }}
    >
      <LabelFlexWrapper>
        <Label>{tPositions("quantity")}</Label>
        <Label>
          {tPositions("max_quantity")}{" "}
          {calculateRemainingQuantity(posAmount, partialPositionTriggers)}
        </Label>
      </LabelFlexWrapper>
      <InputRow>
        <Input
          error={hasError}
          placeholder={placeholder ?? "0"}
          type="number"
          min={0}
          max={calculateRemainingQuantity(posAmount, partialPositionTriggers)}
          style={{ fontSize: "10px" }}
          rightAccessory={
            <PartialMaxButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  partialPositionType === NotificationTypeEnum.TP &&
                  setMaxPartialTPValue
                ) {
                  setMaxPartialTPValue(
                    calculateRemainingQuantity(
                      posAmount,
                      partialPositionTriggers
                    )
                  );
                }
                if (
                  partialPositionType === NotificationTypeEnum.SL &&
                  setMaxPartialSLValue
                ) {
                  setMaxPartialSLValue(
                    calculateRemainingQuantity(
                      posAmount,
                      partialPositionTriggers
                    )
                  );
                }
              }}
              type="button"
            >
              {tPositions("max")}
            </PartialMaxButton>
          }
          {...inputRegister}
        />
      </InputRow>
      <SliderInputWrapper showSlider>
        <SliderSmallWrapper>
          <Slider
            renderTrack={(props, { index }) => (
              <StyledTrack
                {...(props as any)}
                index={index}
                color={COLORS.highlight.one}
              />
            )}
            renderThumb={(props) => (
              <StyledThumb
                {...(props as any)}
                color={COLORS.highlight.one}
                width={`calc(100% / ${6})`}
              >
                <div />
              </StyledThumb>
            )}
            min={0}
            max={calculateRemainingQuantity(posAmount, partialPositionTriggers)}
            value={inputPositionAmount}
            step={posAmount / 100}
            onChange={(value) => {
              if (typeof value === "number") {
                const roundedValue = roundToStepSize(
                  value,
                  minAmountSize,
                  amountPrecision
                );
                onChangeSliderValue(roundedValue);
              }
            }}
          />
        </SliderSmallWrapper>
        <PartialPositionPercentage
          style={{
            paddingRight: 0,
            margin: "auto",
            width: 40,
            textAlign: "center",
          }}
        >
          {`${calculatePositionPercentage(
            inputPositionAmount,
            calculateRemainingQuantity(posAmount, partialPositionTriggers)
          )} %`}
        </PartialPositionPercentage>
      </SliderInputWrapper>
      {error?.type ===
        FormValidatorKeysEnum.positionAmountGreaterThanPosition && (
        <WarningLabel>
          {t("partialPositionAmount", {
            size: calculateRemainingQuantity(
              posAmount,
              partialPositionTriggers
            ),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {t("cannotBeLessThanSize", { size: minAmountSize })}
        </WarningLabel>
      )}
    </InputWrapper>
  );
}
