import moment from "moment";
import { TFunction } from "react-i18next";
import { TimePeriodEnum } from "../interfaces/TimePeriod";
import {
  GetTradeHistory200ResponseTradeHistoryInner,
  InstrumentTypeResponse,
} from "../codegen-api";

const translateKey = "app:utils:date";

/**
 * Given a time period, get the start time in UNIX seconds
 * eg. timePeriod = DAILY, start time will be today (start of day)
 * eg. timePeriod = WEEKLY, start time will be this monday (start of week)
 * @param timePeriod
 * @param customStartTime Optional custom start time in seconds. If not provided, use Date.now()
 */
export const getStartTimeSeconds = (
  timePeriod: TimePeriodEnum,
  customStartTime?: number
): number => {
  const startTime = customStartTime ? moment.unix(customStartTime) : moment();
  switch (timePeriod) {
    case TimePeriodEnum.HOURLY:
      return startTime.utc().startOf("hour").unix();
    case TimePeriodEnum.DAILY:
      return startTime.utc().startOf("day").unix();
    case TimePeriodEnum.WEEKLY:
      return startTime.utc().subtract(1, "week").startOf("day").unix();
    case TimePeriodEnum.MONTHLY:
      return startTime.utc().subtract(1, "months").startOf("day").unix();
    case TimePeriodEnum.THREE_MONTHS:
      return startTime.utc().subtract(3, "months").startOf("day").unix();
    case TimePeriodEnum.SIX_MONTHS:
      return startTime.utc().subtract(6, "months").startOf("day").unix();
    case TimePeriodEnum.YEARLY:
      return startTime.utc().subtract(1, "year").startOf("day").unix();
    default:
      return 0;
  }
};

/**
 * Given a time period, get the start time in UNIX seconds
 * eg. timePeriod = DAILY, start time will be 24 hours before current time
 * eg. timePeriod = WEEKLY, start time will be 1 week before current time
 * @param timePeriod
 * @param customStartTime Optional custom start time in seconds. If not provided, use Date.now()
 */
export const getStartTimeExact = (
  timePeriod: TimePeriodEnum,
  customStartTime?: number
): number => {
  const currentTime = customStartTime ? moment.unix(customStartTime) : moment();
  switch (timePeriod) {
    case TimePeriodEnum.HOURLY:
      return currentTime.subtract(1, "hours").unix();
    case TimePeriodEnum.DAILY:
      return currentTime.subtract(1, "days").unix();
    case TimePeriodEnum.WEEKLY:
      return currentTime.subtract(1, "weeks").unix();
    case TimePeriodEnum.MONTHLY:
      return currentTime.subtract(1, "months").unix();
    case TimePeriodEnum.THREE_MONTHS:
      return currentTime.subtract(3, "months").unix();
    case TimePeriodEnum.SIX_MONTHS:
      return currentTime.subtract(6, "months").unix();
    case TimePeriodEnum.YEARLY:
      return currentTime.subtract(1, "years").unix();
    default:
      return 0;
  }
};

/**
 * @param nanos Nanoseconds in string
 * @returns Number of seconds
 */
export const nanosToSeconds = (nanos: string | number) =>
  Number(String(nanos).slice(0, -9));

export const nanosToMillis = (nanos: string | number) =>
  Number(String(nanos).slice(0, -6));

/**
 * @param seconds to nanos in string
 * @returns Number of nanoseconds
 */
export const secondsToNanos = (seconds: string | number) =>
  Number(seconds) * 1e9;

/**
 * Given a time period, return the appropriate resolution (interval) in seconds
 * @param timePeriod Time period
 */
export const getResolutionSecondsForTimePeriod = (
  timePeriod: TimePeriodEnum
): number => {
  switch (timePeriod) {
    case TimePeriodEnum.HOURLY:
      // 30 seconds
      return 30;
    case TimePeriodEnum.DAILY:
      // Every minute
      return 60;
    case TimePeriodEnum.WEEKLY:
      // Every 30 mins
      return 60 * 30;
    case TimePeriodEnum.MONTHLY:
      // Every hour
      return 60 * 60;
    case TimePeriodEnum.THREE_MONTHS:
      // Every 2 hours
      return 60 * 60 * 2;
    case TimePeriodEnum.SIX_MONTHS:
      // Every 4 hours
      return 60 * 60 * 4;
    case TimePeriodEnum.YEARLY:
      // Every Day
      return 60 * 60 * 24;
    default:
      return 0;
  }
};

/**
 * Given an expiry, returns the remaining time to expiry in weeks, days, hours, minutes, and seconds
 * @param expiry UNIX timestamp
 */
export const timeLeftToExpiry = (
  expiry: number | string,
  currentDate?: Date
) => {
  const exp = moment.unix(Number(expiry));
  const duration = moment.duration(exp.diff(currentDate ?? new Date()));

  // Get weeks and subtract from duration
  let weeks = duration.weeks();
  // Max is weeks, so if duration is >1 month
  // we use asWeeks() to convert it all into weeks
  if (duration.asWeeks() > 4) {
    weeks = Math.floor(duration.asWeeks());
  }
  duration.subtract(weeks, "weeks");

  // Get Days and subtract from duration
  const days = duration.days();
  duration.subtract(days, "days");

  // Get hours and subtract from duration
  const hours = duration.hours();
  duration.subtract(hours, "hours");

  // Get Minutes and subtract from duration
  const minutes = duration.minutes();
  duration.subtract(minutes, "minutes");

  const seconds = duration.seconds();

  // Never negative
  return {
    weeks: Math.max(0, weeks),
    days: Math.max(0, days),
    hours: Math.max(0, hours),
    minutes: Math.max(0, minutes),
    seconds: Math.max(0, seconds),
  };
};

/**
 * eg. "1D", "20H", etc.
 * @param expiry UNIX timestamp
 */
export const shorthandTimeLeftToExpiry = (
  t: TFunction,
  expiry: number | string,
  currentDate?: Date
) => {
  const { weeks, days, hours, minutes, seconds } = timeLeftToExpiry(
    expiry,
    currentDate
  );

  if (weeks) {
    return t(`${translateKey}:short_week`, { n: weeks });
  }
  if (days) {
    return t(`${translateKey}:short_day`, { n: days });
  }
  if (hours) {
    return t(`${translateKey}:short_hour`, { n: hours });
  }
  if (minutes) {
    return t(`${translateKey}:short_min`, { n: minutes });
  }

  // If a few seconds still remaining, return 1M
  if (seconds) {
    return t(`${translateKey}:short_one_min`);
  }
  return t(`${translateKey}:expired`);
};
/**
 * eg. "1W 2D 5H 26M"
 * @param expiry UNIX timestamp
 */
export const longhandTimeLeftToExpiry = (
  t: TFunction,
  expiry: number | string,
  currentDate?: Date,
  showSeconds?: boolean
) => {
  const { weeks, days, hours, minutes, seconds } = timeLeftToExpiry(
    expiry,
    currentDate
  );

  const timeLeft: string[] = [];
  if (weeks) {
    timeLeft.push(t(`${translateKey}:short_week`, { n: weeks }));
  }
  if (days) {
    timeLeft.push(t(`${translateKey}:short_day`, { n: days }));
  }
  if (hours) {
    timeLeft.push(t(`${translateKey}:short_hour`, { n: hours }));
  }
  if (minutes) {
    timeLeft.push(t(`${translateKey}:short_min`, { n: minutes }));
  } else if (seconds && !showSeconds) {
    // If a few seconds still remaining, return 1M
    timeLeft.push(t(`${translateKey}:short_one_min`));
  }

  if (seconds && showSeconds) {
    timeLeft.push(t(`${translateKey}:short_sec`, { n: seconds }));
  }

  if (!timeLeft.length) {
    return undefined;
  }

  // Only show the first 4 values
  return timeLeft.slice(0, 4).join(" ");
};

export const getTimeAgo = (pastDate: moment.MomentInput, t: TFunction) => {
  const compareToDate = moment(pastDate);
  const now = moment();
  const diff = now.diff(compareToDate);

  // Date must be in the past, else is "Just now"
  if (diff >= 0) {
    const duration = moment.duration(diff);
    const oneMonthAgo = now.clone().subtract(1, "month");
    if (compareToDate <= oneMonthAgo) {
      const value = Math.round(duration.asMonths());
      return value > 1
        ? t(`${translateKey}:n_months`, { n: value })
        : t(`${translateKey}:one_month`);
    }

    const oneWeekAgo = now.clone().subtract(1, "week");
    if (compareToDate <= oneWeekAgo) {
      const value = Math.round(duration.asWeeks());
      return value > 1
        ? t(`${translateKey}:n_weeks`, { n: value })
        : t(`${translateKey}:one_week`);
    }

    const oneDayAgo = now.clone().subtract(1, "day");
    if (compareToDate <= oneDayAgo) {
      const value = Math.round(duration.asDays());
      return value > 1
        ? t(`${translateKey}:n_days`, { n: value })
        : t(`${translateKey}:one_day`);
    }

    const oneHourAgo = now.clone().subtract(1, "hour");
    if (compareToDate <= oneHourAgo) {
      const value = Math.round(duration.asHours());
      return value > 1
        ? t(`${translateKey}:n_hours`, { n: value })
        : t(`${translateKey}:one_hour`);
    }

    const oneMinuteAgo = now.clone().subtract(1, "minute");
    if (compareToDate <= oneMinuteAgo) {
      const value = Math.round(duration.asMinutes());
      return value > 1
        ? t(`${translateKey}:n_mins`, { n: value })
        : t(`${translateKey}:one_min`);
    }
  }

  return t(`${translateKey}:just_now`);
};

// todo: change mock start date
// mock 3 month period
export const rewardsStartDate = new Date("2024-01-01");
export const rewardsEndDate = new Date("2024-02-28");

export const rewardsStartDateNano = rewardsStartDate.getTime() * 1000000;
export const rewardsEndDateNano = rewardsEndDate.getTime() * 1000000;

export function getRewardsTradeHistory(
  tradeHistory: GetTradeHistory200ResponseTradeHistoryInner[],
  startDate: Date
) {
  const startOfDay = (date: Date) => new Date(date.setHours(0, 0, 0, 0));
  const formatDate = (date: Date) =>
    date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  const dailySums = new Map<string, number>();

  tradeHistory.forEach((trade) => {
    const tradeDate = new Date(Number(trade.created_timestamp) / 1000000);
    const normalizedDate = startOfDay(tradeDate);

    if (normalizedDate >= startOfDay(new Date(startDate))) {
      const formattedDate = formatDate(normalizedDate);
      const currentValue = dailySums.get(formattedDate) || 0;
      const additionalValue =
        trade.instrument_type === InstrumentTypeResponse.Option
          ? parseFloat(trade.avg_price || "0") * parseFloat(trade.amount)
          : parseFloat(trade.spot_price || "0") * parseFloat(trade.amount);
      dailySums.set(formattedDate, currentValue + additionalValue);
    }
  });

  // Generate all dates for the 56-day period
  const allDates = [];
  for (let day = 0; day < 100; day += 1) {
    const currentDate = new Date(startDate.getTime());
    currentDate.setDate(startDate.getDate() + day);
    if (currentDate > rewardsEndDate) {
      break;
    }
    const formattedDate = formatDate(currentDate);
    allDates.push({
      [formattedDate]: dailySums.get(formattedDate) || 0,
    });
  }

  return allDates;
}

/**
 * Given a time period, returns the number of seconds for that period
 * @param timePeriod
 */
export const secondsFromTimePeriod = (timePeriod: TimePeriodEnum): number => {
  switch (timePeriod) {
    case TimePeriodEnum.HOURLY:
      return 60 * 60;
    case TimePeriodEnum.DAILY:
      return secondsFromTimePeriod(TimePeriodEnum.HOURLY) * 24;
    case TimePeriodEnum.WEEKLY:
      return secondsFromTimePeriod(TimePeriodEnum.DAILY) * 7;
    case TimePeriodEnum.MONTHLY:
      return secondsFromTimePeriod(TimePeriodEnum.DAILY) * 30;
    case TimePeriodEnum.THREE_MONTHS:
      return secondsFromTimePeriod(TimePeriodEnum.MONTHLY) * 3;
    case TimePeriodEnum.SIX_MONTHS:
      return secondsFromTimePeriod(TimePeriodEnum.MONTHLY) * 6;
    case TimePeriodEnum.YEARLY:
      return secondsFromTimePeriod(TimePeriodEnum.DAILY) * 365;
    default:
      return 0;
  }
};

/**
 * Converts a nanosecond timestamp to a formatted UTC date string.
 * @param {number} nanoseconds - The timestamp in nanoseconds.
 * @returns {string} - The formatted UTC date in "DD MMMM YYYY" format.
 */
export function nanosecondsToUTCDate(nanoseconds: number): string {
  const date = new Date(nanoseconds / 1e6); // Convert to milliseconds
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
}
